/* types */

a {
    text-decoration: none;
    color: $black;
    transition: all .5s ease 0s;
    &:hover {
        color: inherit;
        font-family: 'robo-medium';
    }
}

.lead {
    font-size: 1.5rem;
    font-weight: normal;
}

h1 {
    font-size: 1.5rem;
    font-weight: normal;
}

h5 {
    font-size: 1rem;
    font-weight: normal;
    font-family: 'robo-medium';
}