.nav-img-text {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: none;
    align-items: center;
    justify-content: center;
    text-align: center;
}



.grid-item:hover {
    .nav-img-text {
        font-family: 'robo-light';
        display: flex;
    }
    .nav-img {
        opacity: .2;
    }
}

.nav-img {
    transition: all .5s ease 0s;
    position: relative;
    border: none;
    border-radius: 0;
    cursor: pointer;
}


#img-wrap {
    height: 100%;
    width: 100%;
    .img-list {
        min-height: 100%;
        width: 100%;
        object-fit: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        margin-bottom: 100px;
        img {
            object-fit: contain;
            width: 100%;
            max-width: 100%;
            max-height: 100%;
        }
    }
}