/* nav */

.top-nav {
    background: rgba(255, 255, 255, .85);
    font-size: 1rem;
    padding: .7rem 0;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;
    border-bottom: 1px solid $gray-200;
    a {
        margin: 0;
        margin: 0 .5rem;
        &.active {
            font-family: 'robo-medium';
        }
    }
}

.nav-serie-single {
    padding-top: 1rem;
    //border-top: 1px solid $gray-200;
    margin-top: 3rem;
    p {
        margin-bottom: 0;
    }
}

.slide-nav {
    margin-top: 4rem;
    a {
        margin: 0 .75rem;
        &.active {
            font-family: 'robo-bold';
        }
    }
}

.scroll-up {
    visibility: visible;
    background: rgba(255, 255, 255, .85);
    font-size: 1rem;
    padding: 1rem 0;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
}

.scroll-down {
    visibility: hidden;
}