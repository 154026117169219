.swiper-container {
    .swiper-slide {
        text-align: center;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.swiper-button-next,
.swiper-container-rtl {
    right: -20px;
    left: auto;
}

.swiper-button-prev,
.swiper-container-rtl {
    left: -20px;
    right: auto;
}

.swiper-button-prev,
.swiper-button-next {
    color: $gray-600;
    &:after {
        font-size: 1.25rem;
    }
}

.controls-wrap {
    position: relative;
    margin-top: 2rem;
}

.carousel-control-prev,
.carousel-control-next {
    z-index: 3;
    color: $black;
    opacity: 1;
    &:hover {
        color: $gray-500;
    }
    &:focus {
        color: $gray-500;
    }
}

.carousel-control-next {
    right: 0;
}

.carousel-control-prev {
    left: 0;
}

.carousel-indicators {
    position: relative;
}

.carousel-indicators [data-bs-target] {
    background-color: $gray-500;
    width: 15px;
    height: 2px;
    &.active {
        background-color: $black;
    }
    @include media-breakpoint-up(md) {
        width: 30px;
        height: 3px;
    }
}

.carousel-inner {
    .carousel-item {
        .img-container {
            width: 100%;
            object-fit: contain;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                object-fit: contain;
                width: 100%;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}