//@import "_custom-variables";
// the ~ allows to reference in node_modules //
@import "~bootstrap/scss/bootstrap";
@import "custom-main";
@import "custom-nav";
@import "custom-images";
@import "custom-types";
@import "custom-carousel";
@import "custom-modal";
@import "custom-grid";
@import "custom-buttons";