.modal-dialog {
    max-width: 100%;
    min-width: 100%;
    height: 100%;
    margin: 0px;
    .modal-content {
        border: none;
        border-radius: 0;
        height: 100%;
        
        .modal-header { 
            padding: .5rem 1rem;
            @include media-breakpoint-up(md) {
                padding: 1rem 1rem;
            }
            .modal-title {
                margin: 0 auto;
                padding-right: 1rem;
                font-size: 1rem;
            }
            .btn-close {
                position: absolute;
                right: .5rem;
                top: 1rem;
                @include media-breakpoint-up(md) {
                    top: 1.5rem;
                }
            }
        }
    }
}

.img-container > div > p {
    font-size: .8rem;
}