html {
    height: 100%;
}

body {
    min-height: 100%;
    width: 100%;
    overflow-x: hidden;
    font-family: 'robo-light';
    color: $black;
}

.start-wrapper {
    width: 100vw;
    height: 100vh;
    background-color: $white;
    position: absolute;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: 100vh;
    background-position: bottom center;
    background-color: hsla(0, 0%, 100%, 0.65);
    background-blend-mode: overlay;
    @include media-breakpoint-up(sm) {
        background-size: 110vh;
    }
    @include media-breakpoint-up(md) {
        background-size: 120vh;
    }
}

.start-nav-left,
.start-nav-right {
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    align-items: center;
    transition: all 1s ease 0s;
    a {
        display: block;
        padding: 2rem;
        text-decoration: none;
        width: 100%;
        color: $black;
        transition: all 1s ease 1s;
        &:hover {
            font-family: 'robo-bold';
        }
    }
    @include media-breakpoint-down(md) {
        align-items: self-start;
        padding-top: 10%;
    }
}

.start-nav-left {
    background-color: $white;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    background-repeat: no-repeat;
    background-position: center right;
    background-color: hsla(0, 0%, 100%, 0.5);
    background-blend-mode: overlay;
    background-image: url('/public/assets/img/jens-153-left.jpg');
    background-size: 60vh;
    &:hover {
        background-color: none;
        background-blend-mode: normal;
    }
    @include media-breakpoint-up(md) {
        background-size: 70vh;
    }
}

.start-nav-right {
    background-color: $white;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    background-repeat: no-repeat;
    background-position: center left;
    background-color: hsla(0, 0%, 100%, 0.5);
    background-blend-mode: overlay;
    background-image: url('/public/assets/img/jens-153-right.jpg');
    text-align: end;
    background-size: 60vh;
    &:hover {
        background-color: none;
        background-blend-mode: normal;
    }
    @include media-breakpoint-up(md) {
        background-size: 70vh;
    }
}


/*
&:nth-child(2) {
    text-align: end;
}
*/

.con-first {
    margin-top: 6rem;
}

.serie-text {
    margin-top: 4rem
}

.animation {
    animation: slideUp .5s ease forwards;
    position: relative;
    transition: all .5s ease;
}

@keyframes slideUp {
    0% {
        transform: translateY(300px);
    }
    100% {
        transform: translateY(0px);
    }
}

#ajaxloading {
    border-radius: 4px;
    border: 1px solid #5d7e9e;
    padding-top: 24px;
    padding-bottom: 30px;
    color: #009fe3;
    position: fixed;
    width: 300px;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -100px;
    z-index: 999999;
    text-align: center;
    background-color: #374b5e;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.3);
    display: none;
    color: #fff;
}

#ajaxloading span {
    font-size: 18px;
    letter-spacing: 0.05em;
    padding-left: 8px;
    font-weight: 600;
}

#ajaxbg {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 99999;
    position: fixed;
    display: none;
}

#loading {
    display: none;
    width: 34px;
    height: 34px;
    text-align: center;
    left: 50%;
    margin-left: -17px;
    color: #009fe3;
    position: absolute;
}