a.btn-back {
    background: $white;
    border-radius: 0px;
    border: 1px solid $gray-500;
    color: $black;
    &:hover {
        font-family: 'robo-light';
        background: $gray-500;
        color: $white;
    }
}