.grid {
    background: $white;
    display: flex;
    flex-wrap: wrap;
    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

.grid-sizer,
.grid-item {
    width: 50%;
    padding: .25rem;
    @include media-breakpoint-up(md) {
        width: 25%;
    }
}

.grid-item-w2 {
    width: 100%;
    padding: .25rem;
    @include media-breakpoint-up(md) {
        width: 50%;
    }
}

.grid img {
    display: block;
    width: 100%;
}

.left_abstand {
    padding-left: 2rem;
}

.right_abstand {
    padding-right: 2rem;
}

.left_right_abstand {
    padding-right: 2rem;
    padding-left: 2rem;
}